class BaseError extends Error {
    constructor (message) {
        super(message);
        this.message = message;
        console.log(message);
    }
}

export class ApiSessionSetupError extends BaseError {
}


export class ApiSessionReloadError extends BaseError {
}


export class ApiFetchStepsError extends BaseError {
}

export class ReservationQueueStepError extends BaseError {
}


export class ApiStepSubmitError extends BaseError {
    constructor (message, step, validationErrors = []) {
        super(message);
        this.step = step;
        this.validationErrors = validationErrors;
    }
}

/**
 * Intended to be used as an error case for
 * unhandled API fetch errors
 */
export class ApiFetchError extends BaseError {
}

/**
 * Intended to be used as an error case where
 * the message can be displayed as a validation
 * error for a form input
 */
export class ApiResultError extends BaseError {
}

export class ContentApiError extends BaseError {
}

/**
 * Api step-specific error case for insurances (PolicyAssignment).
 * Intended to be used for assignment preset assumption issues,
 * that might be treatable by the user.
 */
export class PolicyAssignmentPresetError extends BaseError {
}
