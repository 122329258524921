import {ApiPrepareInput, ApiPrepareStep, ApiRawFlightSegments, ApiRawOptionsBus, ApiRawOptionsCabinType, ApiRawOptionsHomeAirport, ApiRawOptionsPayment, ApiRawOptionsSalutationCode, ApiRawOptionsShipAirport, ApiRawOptionsShipFlight, ApiRawOptionsTitle, ApiRawOptionsTrain, ApiRawOptionsTransportType, ApiRawOptionsVip, ApiRawStep, ApiRawTrainToPlaneOption, PrepareFlightSegments, PrepareOptionsBus, PrepareOptionsCabinType, PrepareOptionsHomeAirport, PrepareOptionsPayment, PrepareOptionsSalutationCode, PrepareOptionsShipAirport, PrepareOptionsShipFlight, PrepareOptionsTitle, PrepareOptionsTrain, PrepareOptionsTransportType, PrepareOptionsVip, PrepareTrainToPlaneOption} from './interface';

import * as Constants from '../../../config/constants';
import {NETMATCH_STEP_RESERVATION_QUEUE} from '../../../config/constants';
import StringUtil from '../../../util/string';
import {ReservationQueueStepError} from '../../../error/errors';
import {getLocale} from '../../../intl';
import app from '../../../app/app';

export const data = {
    parseSteps (raw: Array<ApiRawStep>) {
        let adultIndex = 0;
        let childIndex = 0;
        let occupantIndex = 0;
        const result: Array<ApiPrepareStep> = [];
        raw.forEach((element: ApiRawStep) => {
            // ATTENTION !!! ein API key heißt 'attributes' das mag aber Backbone nicht wirklich
            // und führt echt zu beschissenen Nebenwirkungen,
            // daher wird dieser key hier umbenannt in 'netMatchAttributes'
            const item: ApiPrepareStep = {
                netMatchAttributes: element.attributes || [],
                descriptions: element.descriptions,
                hasSubmits: element.hasSubmits,
                id: element.id,
                status: element.status,
                type: element.type,
                title: element.title,
                inputs: []
            };

            // TUICUNIT-156: remove All NOT_APPLICABLE inputs with the Validators
            if (item.status === Constants.NETMATCH_STEP_STATUS_NOT_APPLICABLE) {
                element.inputs = [];
            }

            // TUICUNIT-1625 API tracking
            if (item.id === Constants.NETMATCH_STEP_CLIENT) {
                item.status = Constants.NETMATCH_STEP_STATUS_COMPLETED;
            }

            /* if (item.id === 'cabinSelection/cabin-1' && item.status === Constants.NETMATCH_STEP_STATUS_OPEN) {
                item.status = Constants.NETMATCH_STEP_STATUS_COMPLETED_WITH_DEFAULT;
            } */

            /*  if (item.id === Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT && item.status === Constants.NETMATCH_STEP_STATUS_PENDING) {
                element.inputs = [];
            }
            if (item.id === Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT && item.status === Constants.NETMATCH_STEP_STATUS_PENDING) {
                element.inputs = [];
            } */

            const regexAdults = new RegExp(Constants.NETMATCH_STEP_ADULT);
            const regexChildren = new RegExp(Constants.NETMATCH_STEP_CHILD);
            if (item.id.match(regexAdults)) {
                adultIndex = adultIndex + 1;
                item.adultIndex = adultIndex;
                item.occupantIndex = occupantIndex = occupantIndex + 1;
            }
            if (item.id.match(regexChildren)) {
                childIndex = childIndex + 1;
                item.childIndex = childIndex;
                item.occupantIndex = occupantIndex = occupantIndex + 1;
            }
            // console.log(item.id.match(regexAdults));

            if (element.inputs) {
                element.inputs.forEach((part: any) => {
                    const input: ApiPrepareInput = {
                        id: part.property,
                        label: part.label,
                        property: part.property,
                        type: part.type,
                        validators: this.validatorsParse(part.validators),
                        // validators: part.validators,
                        noteToDeveloper: part.noteToDeveloper,
                        defaultValue: part.values.default || '',
                        resultValue: part.values.result || '',
                        inputValue: part.values.input || '',
                        previouslySubmitted: part.values.previouslySubmitted || '',
                        staticOptions: part.staticOptions || null
                    };

                    // new Case Insurance policyAssignment -> status Completed, but they have non resultValue only have a defaultValue
                    if (item.status === Constants.NETMATCH_STEP_STATUS_COMPLETED && input.id === Constants.NETMATCH_INPUT_ASSIGN_TO) {
                        if (!input.resultValue && input.defaultValue) {
                            console.log('%c policyAssignment AssignTo value Work a round ', 'background: #ff0021; color: #000000', part.values);
                            input.resultValue = input.defaultValue;
                        }
                    }

                    // TUICUNIT-1625 API tracking
                    if (input.id === Constants.NETMATCH_INPUT_CLIENT) {
                        input.validators = [];
                    }

                    if (part.options) {
                        /** via mainType */
                        switch (item.type) {
                            case Constants.NETMATCH_STEP_TYPE_CABINTYPE:
                                input.options = this.cabinTypeOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_SHIPBOUNDTRANSPORTTYPE:
                            case Constants.NETMATCH_STEP_TYPE_HOMEBOUNDTRANSPORTTYPE:
                                input.options = this.transportTypeOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_SHIPBOUNDBUS:
                            case Constants.NETMATCH_STEP_TYPE_HOMEBOUNDBUS:
                                input.options = this.transportBusOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_SHIPBOUNDAIRPORT:
                                input.options = this.transportAirportShipOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_HOMEBOUNDAIRPORT:
                                input.options = this.transportAirportHomeOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_SHIPBOUNDFLIGHT:
                            case Constants.NETMATCH_STEP_TYPE_HOMEBOUNDFLIGHT:
                                if (input.id !== Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID) {
                                    input.options = this.transportFlightShipOptions(part.options);
                                } else {
                                    // TUICUNIT-2534
                                    const selectedFlightInputs = item.inputs.find(flight => flight.id === Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID) || null;
                                    const selectedFlightId = selectedFlightInputs ? selectedFlightInputs.resultValue : null;
                                    let trainToPlaneDefaultClass = null;
                                    // maybe a selected flight has a train to plane as default
                                    if (selectedFlightInputs && Array.isArray(selectedFlightInputs.options) && selectedFlightId) {
                                        const selectedFlight = selectedFlightInputs.options.find(flight => flight.id === selectedFlightId) || null;
                                        trainToPlaneDefaultClass = selectedFlight.trainToPlaneDefaultClassIncluded || null;
                                    }
                                    input.options = this.transportTrainToPlaneUpgradeOption(part.options, input.resultValue, selectedFlightId, trainToPlaneDefaultClass);
                                }
                                break;
                            case Constants.NETMATCH_STEP_TYPE_SHIPBOUNDTRAIN:
                            case Constants.NETMATCH_STEP_TYPE_HOMEBOUNDTRAIN:
                                input.options = this.transportTrainOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_PAYMENT:
                                input.options = this.paymentOptions(part.options);
                                break;
                            case Constants.NETMATCH_STEP_TYPE_CABININVIP:
                                input.options = this.vipOptions(part.options);
                                break;
                            default:
                                input.options = part.options;
                                break;
                        }

                        /** via inputType */
                        switch (part.id) {
                            case Constants.NETMATCH_INPUT_ADULT_SALUTATIONCODE:
                            case Constants.NETMATCH_INPUT_CHILD_GENDER:
                            case Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE:
                                input.options = this.salutionCodeOptions(part.options);
                                break;
                            case Constants.NETMATCH_INPUT_ADULT_TITLE:
                            case Constants.NETMATCH_INPUT_INVOICE_TITLE:
                                input.options = this.titleOptions(part.options);
                                break;
                        }
                    }
                    item.inputs.push(input);
                });
            }

            // TUICUNIT-495: Alternative Date Selection inject options
            if (item.id === Constants.NETMATCH_STEP_TRIP_ALTERNATIVES) {
                element.inputs = [
                    {
                        id: Constants.INJECT_NETMATCH_INPUT_TRIP_ALTERNATIVE,
                        property: Constants.INJECT_NETMATCH_INPUT_TRIP_ALTERNATIVE,
                        options: item.netMatchAttributes,
                        label: 'inject by parseSteps',
                        type: 'inject by parseSteps',
                        validators: [],
                        noteToDeveloper: 'inject by parseSteps',
                        defaultValue: 'inject by parseSteps',
                        resultValue: 'inject by parseSteps',
                        inputValue: 'inject by parseSteps'
                    }
                ];
                item.status = Constants.NETMATCH_STEP_STATUS_COMPLETED;
            }
            result.push(item);

            if (item.id === NETMATCH_STEP_RESERVATION_QUEUE) {
                if (item.hasSubmits) {
                    if (item.status !== Constants.NETMATCH_STEP_STATUS_COMPLETED) {
                        if (app && app.ibeController && app.ibeController.ibeStorage && app.ibeController.ibeStorage) {
                            const initialParameter = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER) ?? null;
                            app.ibeController.ibeStorage.deleteStorage();
                            if (initialParameter) {
                                const initialTripcode = initialParameter[Constants.INITIAL_PARAMETER_TRIPCODE];
                                const initialEPackageCode = initialParameter[Constants.INITIAL_PARAMETER_EPACKAGECODE];
                                const enrollUrl = window.location.host.includes('test') ? `https://next.aws.test.meinschiff.com/${getLocale()}/queue/enroll` : `https://meinschiff.com/${getLocale()}/queue/enroll`;
                                window.location.href = `${enrollUrl}?tripCode=${initialTripcode}&packageReference=${initialEPackageCode}`;
                            }
                        }

                        throw new ReservationQueueStepError();
                    }
                }
            }
        });
        console.log('%c parseSteps ', 'background: #222; color: #ffff00', result);
        return result;
    },


    validatorsParse (raw: Array<any>) {
        let result: Array<any> = [];
        if (Array.isArray(raw)) {
            result = [];
            raw.forEach(item => {
                result.push(item);
            });
        }
        return result;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsCabinType>
     */
    cabinTypeOptions (raw: Array<ApiRawOptionsCabinType>) {
        const options: Array<PrepareOptionsCabinType> = [];
        // console.log('%c Stepdate overwrite FeelGood -> FeelGoodPlus', 'background: #ff0021; color: #000000');

        raw.forEach((rawOption: ApiRawOptionsCabinType) => {
            // rawOption.model = rawOption.model.replace('FeelGood', 'FeelGoodPlus');
            const option = {
                categoryTypeCode: rawOption.categoryTypeCode,
                displayText: rawOption.displayText,
                fromPriceCruiseOnly: rawOption.fromPriceCruiseOnly || 0,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false,
                isCapacityExceeded: rawOption.isCapacityExceeded || false,
                model: rawOption.model,
                hasVipTariffAvailable: rawOption.hasVipTariffAvailable || false,
                vipTariff: rawOption.vipTariff || null
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsTransportType>
     */
    transportTypeOptions (raw: Array<ApiRawOptionsTransportType>) {
        const options: Array<PrepareOptionsTransportType> = [];
        raw.forEach((rawOption: ApiRawOptionsTransportType) => {
            const option = {
                canRoundTrip: rawOption.canRoundtrip,
                displayText: rawOption.displayText,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsBus>
     */
    transportBusOptions (raw: Array<ApiRawOptionsBus>) {
        const options: Array<PrepareOptionsBus> = [];
        raw.forEach((rawOption: ApiRawOptionsBus) => {
            const option = {
                AdultPrice: rawOption.adultPrice,
                AdultReturnPrice: rawOption.adultReturnPrice || null,
                ArrivalCityCode: rawOption.arrivalCityCode,
                ArrivalDate: rawOption.arrivalDate,
                ArrivalPickupPointName: rawOption.arrivalPickupPointName,
                ChildPrice: rawOption.childPrice || null,
                ChildReturnPrice: rawOption.childReturnPrice || null,
                DepartureCityCode: rawOption.departureCityCode,
                DepartureDate: rawOption.departureDate,
                DeparturePickupPointName: rawOption.departurePickupPointName,
                displayText: rawOption.displayText,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsShipAirport>
     */
    transportAirportShipOptions (raw: Array<ApiRawOptionsShipAirport>) {
        const options: Array<PrepareOptionsShipAirport> = [];
        raw.forEach((rawOption: ApiRawOptionsShipAirport) => {
            const option = {
                arrivalAirport: rawOption.arrivalAirportName,
                arrivalAirportCode: rawOption.arrivalAirportCode,
                displayText: rawOption.displayText,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsHomeAirport>
     */
    transportAirportHomeOptions (raw: Array<ApiRawOptionsHomeAirport>) {
        const options: Array<PrepareOptionsHomeAirport> = [];
        raw.forEach((rawOption: ApiRawOptionsHomeAirport) => {
            const option = {
                departureAirport: rawOption.departureAirportName,
                departureAirportCode: rawOption.departureAirportCode,
                displayText: rawOption.displayText,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsShipFlight>
     */
    transportFlightShipOptions (raw: Array<ApiRawOptionsShipFlight>) {
        const options: Array<PrepareOptionsShipFlight> = [];

        raw.forEach((rawOption: ApiRawOptionsShipFlight) => {
            const segments: Array<PrepareFlightSegments> = [];
            rawOption.segments.forEach((rawSegmet: ApiRawFlightSegments) => {
                const segment = {
                    adultBaggageAllowance: rawSegmet.adultBaggageAllowance || null,
                    adultBaggageAllowanceBags: rawSegmet.adultBaggageAllowanceBags || null,
                    adultBaggageAllowanceKilograms: rawSegmet.adultBaggageAllowanceKilograms,
                    airlineCode: rawSegmet.airlineCode || null,
                    arrivalAirportCode: rawSegmet.arrivalAirportCode,
                    arrivalDate: rawSegmet.arrivalDate,
                    arrivalTime: rawSegmet.arrivalTime || null,
                    childBaggageAllowance: rawSegmet.childBaggageAllowance || null,
                    childBaggageAllowanceBags: rawSegmet.childBaggageAllowanceBags || null,
                    childBaggageAllowanceKilograms: rawSegmet.childBaggageAllowanceKilograms,
                    departureAirportCode: rawSegmet.departureAirportCode,
                    departureDate: rawSegmet.departureDate,
                    departureTime: rawSegmet.departureTime || null,
                    flightNo: rawSegmet.flightNo,
                    isVirtual: rawSegmet.isVirtual || false,
                    class: rawOption.class
                };
                segments.push(segment);
            });

            const feederSegments: Array<PrepareFlightSegments> = [];
            if (rawOption.feederSegments) {
                rawOption.feederSegments.forEach((rawSegmet: ApiRawFlightSegments) => {
                    const feederSegment = {
                        adultBaggageAllowance: rawSegmet.adultBaggageAllowance || null,
                        adultBaggageAllowanceBags: rawSegmet.adultBaggageAllowanceBags || null,
                        adultBaggageAllowanceKilograms: rawSegmet.adultBaggageAllowanceKilograms,
                        airlineCode: rawSegmet.airlineCode || null,
                        arrivalAirportCode: rawSegmet.arrivalAirportCode,
                        arrivalDate: rawSegmet.arrivalDate,
                        arrivalTime: rawSegmet.arrivalTime || null,
                        childBaggageAllowance: rawSegmet.childBaggageAllowance || null,
                        childBaggageAllowanceBags: rawSegmet.childBaggageAllowanceBags || null,
                        childBaggageAllowanceKilograms: rawSegmet.childBaggageAllowanceKilograms,
                        departureAirportCode: rawSegmet.departureAirportCode,
                        departureDate: rawSegmet.departureDate,
                        departureTime: rawSegmet.departureTime || null,
                        flightNo: rawSegmet.flightNo,
                        isVirtual: rawSegmet.isVirtual || false,
                        class: rawOption.feederClass || rawOption.class
                    };
                    feederSegments.push(feederSegment);
                });
            }

            const option = {
                arrivalAirport: rawOption.arrivalAirport,
                class: rawOption.class,
                cruiseAndFlightPrice: rawOption.cruiseAndFlightPrice,
                departureAirport: rawOption.departureAirport,
                displayText: rawOption.displayText,
                flightIds: rawOption.flightIds,
                highlight: rawOption.highlight || false,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false,
                oneWayPrice: rawOption.oneWayPrice,
                trainToPlaneBlockedOnline: rawOption.trainToPlaneBlockedOnline,
                trainToPlaneClassUpgradeAvailable: rawOption.trainToPlaneClassUpgradeAvailable,
                trainToPlaneDefaultClassIncluded: (rawOption.trainToPlaneDefaultClassIncluded && StringUtil.formatNumberWithOrdinalSuffix(rawOption.trainToPlaneDefaultClassIncluded)) || null,
                trainToPlaneUpgradePrice: rawOption.trainToPlaneUpgradePrice,
                feederSegments: feederSegments,
                segments: segments
            };
            options.push(option);
        });
        return options;
    },

    /**
     * TUICUNIT-2534
     * @param raw Array<ApiRawOptionsShipFlightTrainUpgrate>
     *
     */
    transportTrainToPlaneUpgradeOption (raw: Array<ApiRawTrainToPlaneOption>, inputResultValue: string, selectedFlightId: string | null, trainToPlaneDefaultClass: string | null) {
        const options: Array<PrepareTrainToPlaneOption> = [];
        // console.log(raw, inputResultValue, selectedFlightId, trainToPlaneDefaultClass);
        if (Array.isArray(raw)) {
            if (trainToPlaneDefaultClass === null && selectedFlightId) {
                // TUICUNIT-2591: needs 1 and 2 class option and "none" option
                if (raw.length === 3) {
                    options.push({
                        id: 'none',
                        class: '',
                        sort: 0,
                        isBlocked: false,
                        price: false,
                        isSelectet: !inputResultValue,
                        selectedFlightId: selectedFlightId
                    });
                }
            }

            raw.forEach((rawOption) => {
                if (selectedFlightId && !rawOption.isBlocked && rawOption.combinableWithFlightOptions &&
                    rawOption.combinableWithFlightOptions.indexOf(selectedFlightId) !== -1) {
                    // TUICUNIT-2534: manual sort :( for tuic
                    const option = {
                        class: (rawOption.class && StringUtil.formatNumberWithOrdinalSuffix(rawOption.class)) || '-',
                        sort: (rawOption.class === '1') ? 2 : 1,
                        id: rawOption.id || 'none',
                        isBlocked: rawOption.isBlocked || false,
                        price: rawOption.price || false,
                        isSelectet: rawOption.id === inputResultValue,
                        selectedFlightId: selectedFlightId
                    };
                    options.push(option);
                }
            });
            // TUICUNIT-2534: manual sort :( for tuic
            options.sort((a, b) => {
                return (a.sort > b.sort) ? +1 : -1;
            });
        }

        // console.log(options);

        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsShipAirport>
     */

    transportTrainOptions (raw: Array<ApiRawOptionsTrain>) {
        const options: Array<PrepareOptionsTrain> = [];
        raw.forEach((rawOption) => {
            const priceType = rawOption.priceType === 'Short' || rawOption.priceType === 'Long' ? (Constants.NETMATCH_TRAIN_OPTION_MAP_RAILCRUISE) : rawOption.priceType;
            const option = {
                adultPrice: rawOption.adultPrice,
                childPrice: rawOption.childPrice ? rawOption.childPrice : 0,
                price: rawOption.adultPrice,
                class: rawOption.class,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false,
                totalPrice: rawOption.totalPrice,
                priceType: priceType
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsSalutationCode>
     */
    salutionCodeOptions (raw: Array<ApiRawOptionsSalutationCode>) {
        const options: Array<PrepareOptionsSalutationCode> = [];
        raw.forEach((rawOption) => {
            const option = {
                id: rawOption.id,
                displayText: rawOption.displayText,
                selected: false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsTitle>
     */
    titleOptions (raw: Array<ApiRawOptionsTitle>) {
        const options: Array<PrepareOptionsTitle> = [];
        raw.forEach((rawOption) => {
            const option = {
                id: rawOption.id,
                displayText: rawOption.displayText,
                selected: false
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsPayment>
     */
    paymentOptions (raw: Array<ApiRawOptionsPayment>) {
        const options: Array<PrepareOptionsPayment> = [];
        raw.forEach((rawOption) => {
            const option = {
                id: rawOption.id,
                displayText: rawOption.displayText,
                selected: false,
                type: null,
                position: null,
                isBlocked: !!rawOption.isBlocked
            };
            options.push(option);
        });
        return options;
    },

    /**
     *
     * @param raw Array<ApiRawOptionsVip>
     */
    vipOptions (raw: Array<ApiRawOptionsVip>) {
        const options: Array<PrepareOptionsVip> = [];
        raw.forEach((rawOption) => {
            const option = {
                bookingCode: rawOption.bookingCode || null,
                displayText: rawOption.displayText,
                id: rawOption.id,
                isBlocked: rawOption.isBlocked || false,
                price: rawOption.price || null
            };
            options.push(option);
        });
        return options;
    }
};
