import {Model} from 'backbone';
import StorageUtil from '../../../util/storage';
import {NETMATCH_API_SESSION_ID_KEY, STORAGE_KEY, APP_BACK_LINK, INITIAL_PARAMETER, ALL_QUERY_PARAMETER, TRACKING_PARAMETER, APP_JAKALA_TOKEN} from '../../../config/constants';


export default class IbeStorageModel extends Model {
    init () {
        return this.fetch();
    }

    get (att) {
        return super.get(att);
    }


    saveApiSessionId (apiSessionId) {
        return this.save(NETMATCH_API_SESSION_ID_KEY, apiSessionId);
    }


    unsetApiSessionId () {
        return this.save(NETMATCH_API_SESSION_ID_KEY, null);
    }

    saveAppBackLink (backLink) {
        return this.save(APP_BACK_LINK, backLink);
    }

    saveJToken (jToken) {
        return this.save(APP_JAKALA_TOKEN, jToken);
    }

    saveInitialParameter (tripCode, ePackageCode) {
        tripCode = tripCode ? tripCode.replace(/[^0-9a-zA-Z_-]/g, '') : '';
        ePackageCode = ePackageCode ? ePackageCode.replace(/[^0-9a-zA-Z_-]/g, '') : '';
        return this.save(INITIAL_PARAMETER, {
            tripCode,
            ePackageCode
        });
    }

    saveAllQueryParameters (allQueryParameters) {
        return this.save(ALL_QUERY_PARAMETER, allQueryParameters);
    }

    saveTrackingParams (itemCategory, ecListName, ecListCode, ecListPosition, ecListPage) {
        return this.save(TRACKING_PARAMETER, {
            itemCategory,
            ecListName,
            ecListCode,
            ecListPosition,
            ecListPage
        });
    }

    unsetInitialParameter () {
        return this.save(INITIAL_PARAMETER, {
            tripCode: 'used',
            ePackageCode: 'used'
        });
    }

    deleteStorage () {
        StorageUtil.clear();
        this.clear();
    }

    /**
     * Custom sync implementation that saves the model into the browser storage
     * (using the Storage module) instead of to a remote server.
     * @return {Promise}
     */
    sync (method, model, options = {}) {
        let params = options.data;
        if (options.data) {
            params = options.data;
        } else if (model && (method === 'create' || method === 'update' || method === 'patch')) {
            params = model.toJSON(options);
        } else {
            params = {};
        }
        return new Promise((resolve, reject) => {
            let state;
            let error;

            try {
                state = StorageUtil.get(STORAGE_KEY) || {};
            } catch (e) {
                // try again with clean Storage
                this.deleteStorage();
                try {
                    state = StorageUtil.get(STORAGE_KEY) || {};
                } catch (e2) {
                    error = 'Unable to access storage.';
                }
            }

            switch (method) {
                case 'read':
                    // nothing to do here
                    break;
                case 'delete':
                    if (!StorageUtil.unset(STORAGE_KEY)) {
                        error = 'Unabled to delete model from storage.';
                    }
                    break;
                default: // create / update / patch
                    state = Object.assign(state, params);
                    if (!StorageUtil.set(STORAGE_KEY, state)) {
                        error = `Failed to ${method} model in storage.`;
                    }
            }

            if (!error) {
                if (options.success) {
                    options.success.apply(options.context, [state]);
                }
                resolve(model);
            } else {
                if (options.error) {
                    options.error.apply(options.context, error);
                }
                reject(error);
            }
        });
    }
}
