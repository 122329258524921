import _ from 'underscore';
import ibeApiConfig from '../../../config/ibe-api';
import * as Constants from '../../../config/constants';
import app from '../../../app/app';
import Base from '../../../base/model/base';
import ApiBookingStatusModel from '../../../entities/netmatch-api/model/booking-status';
import ApiStaticInputOptionsCollection from '../../../entities/netmatch-api/collection/static-input-options';
import ApiStepsCollection from '../../../entities/netmatch-api/collection/steps';
import NavigationUtil from '../../../util/navigation';
import {
    ApiSessionSetupError, ApiSessionReloadError, ApiFetchStepsError, ApiStepSubmitError, ReservationQueueStepError
} from '../../../error/errors';

import {NewSession, Session, Steps, Submits} from '../adapter/APICalls';
import {getLocale, getLocaleAPI} from '../../../intl';


export default class ApiSessionModel extends Base {
    /**
     * Called immediatly after the constructor.
     * Init instance properties.
     *
     * @return {[type]} [description]
     */
    initialize () {
        _.bindAll(this, 'submitStep', 'submitSteps', 'setup', 'reload', 'fetchSession', 'fetchStaticInputOptions', 'fetchSteps', 'fetchPreviousSubmits');
        this.idAttribute = 'id';
        this.urlRoot = ibeApiConfig.sessionURL;
        this.bookingStatus = new ApiBookingStatusModel();
        this.staticInputOptions = new ApiStaticInputOptionsCollection(null, {
            parentModel: this
        });
        this.steps = new ApiStepsCollection(null, {
            parentModel: this
        });

        // this.previousSubmits = new ApiPreviousSubmitsCollection(null, {
        //     parentModel: this,
        // });
    }

    /**
     * TODO add doc
     */
    submitStep (stepId, data, reloadSession = true, spinner = true) {
        return this.submitSteps([
            {
                'id': stepId,
                'content': {
                    ...data
                }
            }
        ], reloadSession, spinner);
    }


    /**
     * TODO add doc
     */
    submitSteps (stepsData, reloadSession = true, spinner = true) {
        // TUICIBE-60: reset netmatch Cabin values
        let checkCabinValuesReset = true;
        if (_.indexOf(stepsData, 'noCheckCabinValuesReset') !== -1) {
            checkCabinValuesReset = false;
            stepsData = _.without(stepsData, 'noCheckCabinValuesReset');
        }

        console.log('%c submitSteps ', 'background: #222; color: #ffff00', stepsData);

        return new Promise((resolve, reject) => {

            const invalidStep = stepsData.find(stepData => {
                const step = this.steps.find(s => s.id === stepData.id);
                if (!step) {
                    return true;
                }
                return !step.validateData(stepData).valid;
            });

            if (invalidStep) {
                reject(new ApiStepSubmitError('Validierung fehlgeschlagen', invalidStep));
                return;
            }

            // TUICIBE-60: reset netmatch Cabin values
            if (checkCabinValuesReset === true) {
                stepsData.forEach(data => {
                    const cabinTypeRegEx = new RegExp(Constants.NETMATCH_STEP_CABINTYPE);
                    if (data.id.match(cabinTypeRegEx)) {
                        const matches = cabinTypeRegEx.exec(data.id);
                        stepsData.push({
                            'id': `cabinCategory/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `cabinDeck/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `cabinSelection/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINFLOW_PURE}${matches[1]}`
                        });
                        stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINVIP_PURE}${matches[1]}`
                        });
                        // TUICUNIT-2534 reset flight
                        const stepsToResetTransport = this.steps.filter(step => {
                            if ((step.id === Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT) || (step.id === Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT)) {
                                return true;
                            } else {
                                return null;
                            }
                        });
                        stepsData = stepsToResetTransport.reduce((data, step) => {
                            if (step.get('hasSubmits')) {
                                data.push({
                                    'id': step.id
                                });
                            }
                            return data;
                        }, stepsData);
                    }

                    const cabinCategoryRegEx = new RegExp(Constants.NETMATCH_STEP_CABINCATEGORY);

                    if (data.id.match(cabinCategoryRegEx)) {
                        const matches = cabinCategoryRegEx.exec(data.id);
                        stepsData.push({
                            'id': `cabinDeck/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `cabinSelection/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINFLOW_PURE}${matches[1]}`
                        });
                        stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINVIP_PURE}${matches[1]}`
                        });
                    }
                    const cabinDeckyRegEx = new RegExp(Constants.NETMATCH_STEP_CABINDECK);
                    if (data.id.match(cabinDeckyRegEx)) {
                        const matches = cabinDeckyRegEx.exec(data.id);
                        stepsData.push({
                            'id': `cabinSelection/cabin-${matches[1]}`
                        });
                    }
                });
            }

            // TUICIBE-519: check if TITLE == '' then change to null for a valid submit
            stepsData.forEach(data => {
                if (data.id.match(new RegExp(Constants.NETMATCH_STEP_ADULT))) {
                    if (data.content[Constants.NETMATCH_INPUT_ADULT_TITLE] === '') {
                        data.content[Constants.NETMATCH_INPUT_ADULT_TITLE] = null;
                    }
                }
                if (data.id === Constants.NETMATCH_STEP_INVOICE) {
                    if (data.content[Constants.NETMATCH_INPUT_INVOICE_TITLE] === '') {
                        data.content[Constants.NETMATCH_INPUT_INVOICE_TITLE] = null;
                    }
                }
                // TUICUNIT-2534: if TrainToPlaneUpgradeOptionId default ('') then change to (null)
                if (data.id === Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT && data.content) {
                    if (data.content[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID] === '') {
                        data.content[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID] = null;
                    }
                }
                if (data.id === Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT && data.content) {
                    if (data.content[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID] === '') {
                        data.content[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID] = null;
                    }
                }
            });

            // add special field when policyAssigment steps are submitted
            // console.log('%c insurance policyAssigment Work a round ', 'background: #ff0021; color: #000000');
            stepsData.forEach(data => {
                if (data.id.match(Constants.NETMATCH_STEP_POLICYASSIGNMENT)) {
                    // Work'a'Round: because setMatch set String as default but they want an Array
                    if (!_.isArray(data.content[Constants.NETMATCH_INPUT_ASSIGN_TO])) {
                        const megaFuck1 = data.content[Constants.NETMATCH_INPUT_ASSIGN_TO];
                        const megaFuck2 = megaFuck1.replace('[', '').replace(']', '');
                        data.content[Constants.NETMATCH_INPUT_ASSIGN_TO] = megaFuck2.split(',').map(x => parseInt(x, 10));
                        // data.content[Constants.NETMATCH_INPUT_ASSIGN_TO] = [data.content[Constants.NETMATCH_INPUT_ASSIGN_TO].replace('[', '').replace(']', '')];
                    }
                    // data.content[Constants.NETMATCH_SPECIAL_INPUT_ASSIGN_TO] = Constants.NETMATCH_SPECIAL_INPUT_VALUE_ASSIGN_TO;
                }
            });

            // reset policyAssigment and policyType steps when the insurancePolicy step is submitted
            if (stepsData.find(data => data.id === Constants.NETMATCH_STEP_INSURANCEPOLICIES)) {
                const stepsToReset = this.steps.filter(step => (step.id.match(Constants.NETMATCH_STEP_POLICYASSIGNMENT) || step.id.match(Constants.NETMATCH_STEP_POLICYTYPE)));
                stepsData = stepsToReset.reduce((data, step) => {
                    data.push({
                        'id': step.id
                    });
                    return data;
                }, stepsData);
            }

            // reset cabinCategory, cabinDeck, cabinSelection when the party step is submitted, maybe by change
            if (checkCabinValuesReset === true) {
                stepsData.forEach(data => {
                    const partyRegEx = new RegExp(Constants.NETMATCH_STEP_PARTY);
                    if (data.id.match(partyRegEx)) {
                        const matches = partyRegEx.exec(data.id);
                        /* stepsData.push({
                            'id': `cabinType/cabin-${matches[1]}`
                        }); */
                        stepsData.push({
                            'id': `cabinCategory/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `cabinDeck/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `cabinSelection/cabin-${matches[1]}`
                        });
                        stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINFLOW_PURE}${matches[1]}`
                        });
                        /* stepsData.push({
                            'id': `${Constants.NETMATCH_STEP_CABINVIP_PURE}${matches[1]}`
                        }); */
                    }
                });
            }

            // TUICROLLOUTS-635 reset all cabinCategory, cabinDeck, cabinSelection,and complete TransportStep when the trip date step is submitted
            if (stepsData.find(data => data.id === Constants.NETMATCH_STEP_TRIP)) {
                const stepToResetNumber = this.steps.filter(step => (step.id.match(Constants.NETMATCH_STEP_CABINNUMBER)));
                const stepToResetDeck = this.steps.filter(step => (step.id.match(Constants.NETMATCH_STEP_CABINDECK)));
                const stepToResetType = this.steps.filter(step => (step.id.match(Constants.NETMATCH_STEP_CABINCATEGORY)));

                const stepsToResetTransport = this.steps.filter(step => {
                    if ((step.id === Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE) || (step.id === Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE) || (step.id === Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT) || (step.id === Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT) || (step.id === Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT) || (step.id === Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT) || (step.id === Constants.NETMATCH_STEP_SHIPBOUNDTRAIN) || (step.id === Constants.NETMATCH_STEP_HOMEBOUNDTRAIN) || (step.id === Constants.NETMATCH_STEP_SHIPBOUNDBUS) || (step.id === Constants.NETMATCH_STEP_HOMEBOUNDBUS)) {
                        return true;
                    } else {
                        return null;
                    }
                });

                const stepToReset = stepToResetNumber.concat(stepToResetDeck, stepToResetType, stepsToResetTransport);

                stepsData = stepToReset.reduce((data, step) => {
                    if (step.get('hasSubmits')) {
                        data.push({
                            'id': step.id
                        });
                    }
                    return data;
                }, stepsData);
            }

            // reset insurancePolicy, insuranceTermsAndConditions when the party step is submitted
            if (stepsData.find(data => data.id === Constants.NETMATCH_STEP_PARTY)) {
                stepsData.push({
                    'id': Constants.NETMATCH_STEP_INSURANCEPOLICIES
                });
                stepsData.push({
                    'id': Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS
                });
            }

            // TUICIBE-86: reset netmatch TransportType values if isRoundTrip
            // and now: no reset, we set the ShipBoundTransportType - Value also for the step HomeBoundTransportType
            if (app.ibeController.ibeStorage.get(Constants.IS_ROUNDTRIP)) {
                if (_.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE})) {
                    const shipBoundValue = _.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE});
                    if (shipBoundValue.content) {
                        stepsData.push({
                            'id': Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE,
                            'content': {
                                'Type': shipBoundValue.content.Type
                            }
                        });
                    }
                }
            }

            // TUICIBE-86: reset netmatch Flight values
            if (_.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT})) {
                if (!_.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT})) {
                    stepsData.push({
                        'id': Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT
                    });
                }
            } else if (_.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT})) {
                if (!_.findWhere(stepsData, {'id': Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT})) {
                    stepsData.push({
                        'id': Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT
                    });
                }
            }
            console.log('%c stepsData session ', 'background: #222; color: #ffff00', stepsData, reloadSession);

            /*  const stepSubmit = new ApiStepSubmitsModel({
                stepsData
            }, {
                url: `${ibeApiConfig.multipleSubmitsURL.replace('{apiSession}', this.id)}`
            }); */
            if (spinner) {
                app.trigger('loading:start');
            }

            let promise = Submits.setData(stepsData, this.id)
                .catch((jqXHR) => {
                    throw new ApiStepSubmitError(`Netzwerkfehler (${jqXHR.status}): ${jqXHR.statusText}`);
                });

            if (reloadSession) {
                promise = promise.then(() => this.reload());
            }
            promise
                .then(() => {
                    this.checkStatus();
                    if (spinner) {
                        app.trigger('loading:stop');
                    }
                    resolve();
                })
                .catch((error) => {
                    if (error instanceof ReservationQueueStepError) {
                        reject(error);
                    } else {
                        this.reload()
                            .catch(() => {
                                console.log('Catch internally');
                                reject(new ApiStepSubmitError(error.message));
                            });
                        app.trigger('loading:stop');
                        reject(new ApiStepSubmitError(error.message));
                    }
                });
        });
    }

    /**
     * This submitStep ignore all existing validateData.
     * If sessionId exists set this, this in important for ibe -> restoreBookingState() to reset personal Data before get API Session()
     *
     * @return {Promise}
     **/
    submitStepsIfYouKnowWhatYouDo (stepsData, reloadSession = true, reloadStaticOptions = false, sessionId = null, spinner = true) {
        if (sessionId) {
            this.set('id', sessionId);
        }
        return new Promise((resolve, reject) => {
            /* const stepSubmit = new ApiStepSubmitsModel({
                submits: stepsData
            }, {
                url: `${_.result(this, 'url')}/${ibeApiConfig.multipleSubmitsURL}`
            });

            let promise = stepSubmit.save()
                .catch((jqXHR) => {
                    throw new ApiStepSubmitError(`Netzwerkfehler (${jqXHR.status}): ${jqXHR.statusText}`);
                });
            */

            if (spinner) {
                app.trigger('loading:start');
            }

            let promise = Submits.setData(stepsData, this.id)
                .catch((jqXHR) => {
                    throw new ApiStepSubmitError(`Netzwerkfehler (${jqXHR.status}): ${jqXHR.statusText}`);
                });

            if (reloadSession) {
                promise = promise.then(() => this.reload(reloadStaticOptions));
            }

            promise
                //  .then(resolve)
                .then(() => {
                    app.trigger('loading:stop');
                    resolve();
                })
                .catch((error) => {
                    app.trigger('loading:stop');
                    // this.reload(); YouKnowWhatYouDo, so you don't need this fallback
                    if (error instanceof ReservationQueueStepError) {
                        reject(error);
                    } else {
                        reject(new ApiStepSubmitError(error.message));
                    }
                });
        });
    }

    /**
     * @return {Promise}
     **/
    deleteStepsIfYouKnowWhatYouDo (stepsData, reloadSession = true, sessionId = null) {
        if (sessionId) {
            this.set('id', sessionId);
        }
        return new Promise((resolve, reject) => {

            app.trigger('loading:start');
            let promise = Submits.deleteData(stepsData, this.id);
            if (reloadSession) {
                promise = promise.then(() => this.reload());
            }

            promise
                //  .then(resolve)
                .then(() => {
                    app.trigger('loading:stop');
                    resolve();
                })
                .catch((error) => {
                    app.trigger('loading:stop');
                    reject(new ApiStepSubmitError(error.message));
                    // this.reload(); YouKnowWhatYouDo, so you don't need this fallback
                    // reject(new ApiStepSubmitError(error.message));
                });
        });
    }

    /**
     * Loads the sessions details and the corresponding booking steps.
     * If no sessionId is provided, a new session will be inited.
     * If an invalid (rejected by api) sessionID is provided, a new session will inited as a fallback.
     *
     * @param  {string} [sessionId]
     * @param  {boolean} [fallbackOnNewSession=false] - If 'true', a new session will be created, if it fails to pick up
     *                                                    the an existing session (by sessionId)
     * @return {Promise}
     */
    setup (sessionId, fallbackOnNewSession = false) {
        // console.log(sessionId, fallbackOnNewSession);

        return new Promise((resolve, reject) => {
            this.fetchSession(sessionId)
                .then(this.fetchStaticInputOptions)
                .then(this.fetchSteps)
                .then(() => {
                    app.triggerMethod('stepsData:sync');
                })
                .then(resolve)
                .catch((error) => {

                    // falback to a new session if it was not possible to pick up the given sessionId
                    if (fallbackOnNewSession && sessionId) {
                        resolve(this.setup());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getID () {
        return this.get('id');
    }


    /**
     * Reloads the session (and the therefore the booking status)
     * and also reloads the booking steps
     *
     * @return {Promise}
     */
    reload (reloadStaticOptions = false) {
        // console.log('-----------reload-----------');

        return new Promise((resolve, reject) => {
            const sessionId = this.get('id');
            if (sessionId) {
                this.fetchSession(sessionId)
                    .then(() => {
                        if (reloadStaticOptions) {
                            return this.fetchStaticInputOptions();
                        }
                        return null;
                    })
                    .then(this.fetchSteps)
                    .then(() => {
                        app.triggerMethod('stepsData:sync');
                    })
                    .then(resolve)
                    .catch((e) => {
                        console.log('%c Session reload error ', 'background: #ff0021; color: #000000', sessionId, e);
                        if (e instanceof ReservationQueueStepError) {
                            const initialParameter = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER) ?? null;
                            app.ibeController.ibeStorage.deleteStorage();
                            if (initialParameter) {
                                const initialTripcode = initialParameter[Constants.INITIAL_PARAMETER_TRIPCODE];
                                const initialEPackageCode = initialParameter[Constants.INITIAL_PARAMETER_EPACKAGECODE];
                                const enrollUrl = window.location.host.includes('test') ? `https://next.aws.test.meinschiff.com/${getLocale()}/queue/enroll` : `https://meinschiff.com/${getLocale()}/queue/enroll`;
                                window.location.href = `${enrollUrl}?tripCode=${initialTripcode}&packageReference=${initialEPackageCode}`;
                            }
                        } else {
                            reject(new ApiSessionReloadError(e.message));
                        }
                    });
            } else {
                console.log('-----------reload-----------');
                reject(new ApiSessionReloadError('Keine gültige Session ID vorhanden'));
            }
        });
    }

    /**
     * Reloads the session (and the therefore the booking status)
     * and also reloads the booking steps
     *
     * @return {Promise}
     */
    reloadOnly () {
        // console.log('-----------reload-----------');

        return new Promise((resolve, reject) => {
            const sessionId = this.get('id');
            if (sessionId) {
                this.fetchSession(sessionId)
                    .then(() => {
                        app.triggerMethod('sessionData:only:sync');
                    })
                    .then(resolve)
                    .catch((e) => {
                        console.log('%c Session reload error ', 'background: #ff0021; color: #000000', sessionId, e);
                        reject(new ApiSessionReloadError(e.message));
                    });
            } else {
                console.log('-----------reload-----------');
                reject(new ApiSessionReloadError('Keine gültige Session ID vorhanden'));
            }
        });
    }

    /**
     * Fetch the session.
     * If no sessionId parameter is provided, a new session will be created.
     * Otherwise the session with the given sessionId will be picked up.
     *
     * @param  {string} [sessionId]
     * @return {Promise}
     */
    fetchSession (sessionId) {
        console.log('%c fetchSession ', 'background: #222; color: #ffff00', sessionId);

        return new Promise((resolve, reject) => {
            if (sessionId) {
                this.set('id', sessionId);
                Session.fetchData(sessionId)
                    .then((result) => {
                        // this.set(result);
                        this.bookingStatus.set(result, {
                            parse: true
                        });
                        app.ibeController.ibeStatus.httpStatus = '200';
                        app.ibeController.ibeStatus.httpStatusText = 'OK';
                        app.ibeController.ibeStatus.apiUrl = ibeApiConfig.sessionURL;
                        app.triggerMethod('stepsData:sync');
                        resolve();
                    })
                    .catch((error) => {
                        app.ibeController.ibeStatus.httpStatus = `${error.status}`;
                        app.ibeController.ibeStatus.httpStatusText = error.statusText;
                        app.ibeController.ibeStatus.apiUrl = ibeApiConfig.sessionURL;
                        reject(new ApiSessionSetupError('Failed to fetch session'));
                    });

                /* this.set('id', sessionId);
                this.fetch()
                    .then(resolve)
                    .catch(() => {
                        reject(new ApiSessionSetupError(`Failed to fetch session with id ${sessionId}`));
                    }); */
            } else {
                NewSession.fetchData()
                    .then((result) => {
                        this.set(result);
                        app.triggerMethod('stepsData:sync');
                        app.bookingPages.setPageDataSync();
                        resolve();
                    })
                    .catch((error) => {
                        app.ibeController.ibeStatus.httpStatus = `${error.status}`;
                        app.ibeController.ibeStatus.httpStatusText = error.statusText;
                        app.ibeController.ibeStatus.apiUrl = ibeApiConfig.newSessionURL;
                        reject(new ApiSessionSetupError('Failed to fetch new session'));
                    });

            }
        });
    }


    /**
     * Fetch the static input option lists connected to the current session.
     *
     * @return {jQuery Deferred Object}
     */
    fetchStaticInputOptions () {
        return new Promise((resolve, reject) => {
            this.staticInputOptions.fetch()
                .then(resolve)
                .catch((error) => {
                    app.ibeController.ibeStatus.httpStatus = `${error.status}`;
                    app.ibeController.ibeStatus.httpStatusText = error.statusText;
                    app.ibeController.ibeStatus.apiUrl = `${ibeApiConfig.staticInputOptionsUrl}/${getLocaleAPI()}`;
                    reject(new ApiSessionSetupError(`Failed to fetch static options for api session ${this.get('id')}`));
                });
        });
    }


    /**
     * Fetch the booking steps connected to the current session.
     *
     * @return {jQuery Deferred Object}
     */
    fetchSteps () {
        return new Promise((resolve, reject) => {
            Steps.fetchData(this.get('id'))
                .then((item) => {
                    /* this.steps = new ApiStepsCollection(item, {
                        parentModel: this
                    }); */
                    this.steps.parse(item);
                    //  app.triggerMethod('stepsData:sync');
                    app.bookingPages.setPageDataSync();
                    resolve();
                })
                .catch((error) => {
                    if (error instanceof ReservationQueueStepError) {
                        reject(error);
                    } else {
                        app.ibeController.ibeStatus.httpStatus = `${error.status}`;
                        app.ibeController.ibeStatus.httpStatusText = error.statusText;
                        app.ibeController.ibeStatus.mainErrorSessionId = this.get('id');
                        app.ibeController.ibeStatus.apiUrl = ibeApiConfig.stepsURL;
                        console.log('%c Session fetchSteps error ', 'background: #ff0021; color: #000000', error);
                        reject(new ApiFetchStepsError(`Failed to fetch steps for api session ${this.get('id')}`));
                    }
                });
        });
    }


    /**
     * fetch the previously submitted submits connected to the current session.
     * [Edit] We probably won't need this at all.
     *
     * @return {Promise}
     */
    fetchPreviousSubmits () {
        // console.log('Session: fetchPreviousSubmits');
        return new Promise((resolve, reject) => {
            this.previousSubmits.fetch()
                .then(resolve)
                .catch(() => {
                    reject(new Error(`failed to fetch submits for api session ${this.get('id')}`));
                });
        });
    }

    checkStatus () {
        const currentRoute = app.router.getCurrentFragment();
        if (app.bookingPages.getFirstOpenPage()) {
            // console.log('position: current    -> ', app.bookingPages.indexOf(app.bookingPages.get(currentRoute)));
            // console.log('position: first open -> ', app.bookingPages.indexOf(app.bookingPages.getFirstOpenPage()));
            if (app.bookingPages.indexOf(app.bookingPages.get(currentRoute)) > app.bookingPages.indexOf(app.bookingPages.getFirstOpenPage())) {
                app.inactivitLayer = false;
                const reOpenPageModel = app.bookingPages.getFirstOpenPage();
                // console.log(reOpenPageModel);

                const stringToDisplay = reOpenPageModel.get('page-title');
                const savePath = reOpenPageModel.get('route');

                app.trigger('alert:start', {
                    headline: 'Ihre gewünschte Leistung ist leider nicht mehr Verfügbar.',
                    text: `Bitte aktualisieren Sie Ihre Auswahl. Wir leiten Sie zurück auf den Buchungsschritt: ${stringToDisplay}.`,
                    confirmButtonText: 'Auswahl jetzt aktualisieren',
                    confirmCenter: 'center',
                    closeButtonText: null,
                    closable: false,
                    closeOnBgClick: false,
                    confirmCallback: () => {
                        window.onbeforeunload = null;
                        NavigationUtil.redirect(savePath, true);
                    }
                });
            }
        }
    }
}
